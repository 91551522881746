::-webkit-scrollbar {
  display: none;
}

/* description in modal scroll */
.modalDescriptionContainer::-webkit-scrollbar {
  display: block;
}

/* datagrid in admin view scroll */
.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  display: block;
}

.avatar-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.avatar-image.listing-avatar {
  margin-right: 16px;
}

.avatar-image.navbar-avatar {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.avatar-image.modal-avatar {
  width: 40px;
  height: 40px;
}

.avatar-image.profile-avatar {
  width: 100px;
  height: 100px;
}

.avatar-image.admin-userlist-avatar {
  width: 30px;
  height: 30px;
}